import { Environment } from 'src/app/shared/models/environment.interface';
import { baseEnvironment } from 'src/environments/baseEnvironment.preprod';

export const environment:Environment = {
  ...baseEnvironment,
  organizationPrefix: 'CIO',
  organizationUUID: '45b42b5d-9bda-4e01-b157-5a3baeae7ff9',
  activeLanguage: 'it-IT',
  informedConsentTooltip: `
  Utilizza i seguenti placeholder <br>
  - $$_NAME_$$ per gestire il nome del paziente <br>
  - $$_DOC_DATE_$$ per gestire la data della pratica <br>
  - $$_MEDIC_NAME_$$ per gestire il nome del medico <br>
  - $$_PATIENT_CF_$$ per gestire il codice fiscale del paziente<br>
  - $$_PATIENT_BIRTH_DATE_$$ per gestire la data di nascita del paziente<br>
  - $$_PATIENT_BIRTH_CITY_$$ per gestire la città di nascita del paziente<br>
  - $$_PATIENT_BIRTH_PROVINCE_$$ per gestire la provincia di nascita del paziente<br>
  - $$_PATIENT_RES_CITY_$$ per gestire la città di residenza del paziente<br>
  - $$_PATIENT_RES_PROVINCE_$$ per gestire la provincia di residenza del paziente<br>
  - $$_PATIENT_RES_ADDR_$$ per gestire l'indirizzo di residenza del paziente<br>
    - $$_MEDIC_BIRTH_DATE_$$ per gestire la data di nascita del medico<br>
  - $$_MEDIC_BIRTH_PLACE_$$ per gestire il luogo di nascita del medico<br>
  - $$_MEDIC_CF_$$ per gestire il codice fiscale del medico<br>
  - $$_MEDIC_VAT_NUMBER_$$ per gestire la partita iva del medico<br>
  - $$_MEDIC_STRUCTURE_NAME_$$ per gestire il nome della struttura/studio del medico<br>
  - $$_MEDIC_SPECIALIZATION_$$ per gestire la specializzazione del medico<br>
  - $$_MEDIC_STREET_$$ per gestire la via della struttura/studio del medico<br>
  - $$_MEDIC_CITY_$$ per gestire la città della struttura/studio del medico<br>
  - $$_MEDIC_CAP_$$ per gestire il CAP della struttura/studio del medico<br>
  - $$_MEDIC_PROVINCE_$$ per gestire la provicina della struttura/studio del medico<br>
  - $$_MEDIC_COUNTRY_$$ per gestire il paese della struttura/studio del medico<br>
`,
};
